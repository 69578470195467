<template>
  <v-container :class="{ 'app-page-with-filter': !loading }">
    <v-row v-if="loading">
      <v-col
        class="pa-1"
        v-for="i in 10"
        :key="'skeleton-col' + '-' + i"
        cols="12"
      >
        <v-card class="pa-5">
          <v-skeleton-loader
            :ref="'skeleton-' + i"
            :boilerplate="false"
            type="paragraph"
            :tile="false"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>

    <v-data-iterator
      v-else
      :items="records.items"
      :search="records.search"
      :sort-by="records.sortBy.toLowerCase()"
      :sort-desc="records.sortDesc"
      :multi-sort="records.multiSort"
      :disable-pagination="records.disablePagination"
      :single-select="records.singleSelect"
      light
      loading-text="No hay datos disponibles"
      hide-default-footer
    >
      <template v-slot:header>
        <v-row class="px-4">
          <v-col>
            <v-text-field
              v-model="records.search"
              clearable
              flat
              dark
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Buscar"
              class="no-border-style"
            ></v-text-field>
          </v-col>

          <v-col class="d-flex align-end justify-end">
            <v-btn dark icon @click="exportData()">
              <v-icon class="icon-excel">fas fa-file-excel</v-icon>
            </v-btn>

            <v-btn dark icon @click="openFilter = !openFilter">
              <img class="ma-2" src="@/assets/header/filter.svg" />
            </v-btn>
          </v-col>
        </v-row>

        <v-banner
          class="filtro-banner pr-0 pb-0"
          v-model="openFilter"
          transition="slide-y-transition"
        >
          <v-row class="d-flex align-center">
            <v-col cols="6" xs="6" class="py-0">
              <h3 class="title">Filtros de búsqueda</h3>
            </v-col>

            <v-col cols="6" xs="6" class="text-right py-0">
              <v-btn
                icon
                x-small
                color="grey"
                @click="openFilter = !openFilter"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="pt-4">
            <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="filters.date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :clearable="true"
                    v-model="computedDate"
                    label="Mes"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="minFilterDate"
                  :max="maxFilterDate"
                  v-model="filters.date"
                  type="month"
                  scrollable
                  @input="
                    modal = false;
                    $refs.dialog.save(filters.date);
                  "
                ></v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
              <v-select
                :clearable="true"
                v-model="filters.idTipoLiq"
                :items="filters.tiposFilter"
                item-text="Nombre"
                item-value="id_TipoLiq"
                label="Tipo"
              ></v-select>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="12" xs="12" sm="4" md="4" class="text-right">
              <v-btn color="primary" outlined @click="filtrarLiquidaciones()"
                >Filtrar</v-btn
              >
            </v-col>
          </v-row>
        </v-banner>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.id"
            cols="12"
            @click="goTo(item)"
            class="py-2"
          >
            <v-card class="pa-2">
              <v-list-item two-line>
                <v-list-item-content class="pb-2">
                  <v-list-item-title class="mes">{{
                    item.MesDeLiq | gnsDate
                  }}</v-list-item-title>
                  <span class="liquido"
                    >Liquido: $ {{ item.Liquido | format_money }}</span
                  >
                </v-list-item-content>

                <v-list-item-action class="switch-flex">
                  <v-list-item-action-text>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div 
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-switch
                            dense
                            inset
                            color="success"
                            :error="!item.aprobado"
                            right
                            v-model="item.aprobado"
                            @click.native.stop
                            @change="openEstadoModal(item)"
                            class="switch-liquidacion"
                          ></v-switch>
                        </div>
                      </template>
                      <span> {{ (item.aprobado ? 'Rechazar ' : 'Aprobar ' ) }}Liquidación</span>
                    </v-tooltip>
                    <span
                      :class="
                        'tipo-liquidacion tipo ' +
                        slugify(item.DescTipoLiq) +
                        '--text'
                      "
                      v-html="item.DescTipoLiq"
                    ></span>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

              <div class="mb-2">
                <v-btn v-if="item.FechaIng" text disabled class="fechas">
                  <img
                    class="mr-2"
                    width="16px"
                    src="@/assets/liquidaciones/upload.svg"
                  />
                  <span class="date">{{ formatDate(item.FechaIng) }}</span>
                </v-btn>
                <v-btn v-else text disabled class="ml-0 fechas">
                  <img
                    class="mr-2"
                    width="16px"
                    src="@/assets/liquidaciones/upload.svg"
                  />
                  <span class="date">----/--/-- --:--:--</span>
                </v-btn>
                <v-chip v-if="item.FechaVisto" text class="pl-4 fechas">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <img
                          class="mr-2"
                          width="16px"
                          src="@/assets/liquidaciones/visto-icon.svg"
                        />
                        <span class="date">{{
                          formatDate(item.FechaVisto)
                        }}</span>
                      </div>
                    </template>
                    <span>Fecha de visualización</span>
                  </v-tooltip>
                </v-chip>
                <v-chip v-else text class="pl-4 fechas">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <img
                          class="mr-2"
                          width="16px"
                          src="@/assets/liquidaciones/no-visto-icon.svg"
                        />
                        <span class="date">----/--/-- --:--:--</span>
                      </div>
                    </template>
                    <span>Fecha de visualización</span>
                  </v-tooltip>
                </v-chip>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <v-dialog v-model="dialog" width="500" v-if="dialog && dialogContent">
      <v-card>
        <v-card-title class="headline grey lighten-2">Información</v-card-title>

        <v-card-text>{{ dialogContent }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalDescargar"
      width="320px"
      height="286px"
      class="modalDescargar"
    >
      <v-card>
        <v-card-title class="headline"
          >¿En que formato quieres descargar el recibo?</v-card-title
        >
        <v-row>
          <v-col @click="modalDescargar = false">
            <img class="ma-2" src="@/assets/liquidaciones/excel.svg" />
            <h3>EXCEL</h3>
          </v-col>
          <v-col @click="modalDescargar = false">
            <img class="ma-2" src="@/assets/liquidaciones/pdf.svg" />
            <h3>PDF</h3>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalEstado" width="520px" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ modalEstadoTitle }}
          <v-spacer></v-spacer>
          <v-btn x-small icon right @click="autoCloseModalEstado()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ modalEstadoSubtitle }}
        </v-card-subtitle>

        <v-card-text class="pb-0">
          <v-col cols="12" md="12" class="pa-0">
            <v-textarea
              outlined
              name="input-7-4"
              label="Motivo"
              v-model="modalEstadoMotivo"
            ></v-textarea>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="autoCloseModalEstado()"
            >Cerrar</v-btn
          >
          <v-btn
            color="primary"
            :loading="modalEstadoSending"
            :disabled="modalEstadoSending"
            text
            @click="updateStatusLiquidacion()"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getLiquidaciones,
  getLiquidacionesSearch,
  updateEstadoLiquidacion,
} from "@/api/core";
import { gnsDate, slugify } from "@/filters/index";
import { formatMoney } from "@/utils/index";

export default {
  data() {
    return {
      loading: true,
      openFilter: false,
      modalDescargar: false,

      modalEstado: false,
      modalEstadoTitle: "",
      modalEstadoSubtitle: "",
      modalEstadoMotivo: "",
      modalEstadoLiquidacion: null,
      modalEstadoSending: false,

      records: {
        search: "",
        sortDesc: true,
        multiSort: true,
        disablePagination: true,
        sortBy: "mes",

        items: [],
        loading: false,
        current: null,
        src: null,
      },

      filters: {
        tiposFilter: [],
        idTipoLiq: null,
        //date: new Date().toISOString().substr(0, 7),
        date: null,
      },

      menu: false,
      modal: false,

      dialog: false,
      dialogContent: null,

      maxFilterDate: null,
      minFilterDate: null,
    };
  },

  computed: {
    ...mapGetters(["user"]),

    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },

    computedDate: {
      get: function () {
        if (this.filters.date) {
          let info = this.filters.date.split("-");
          return info[1] + "/" + info[0];
        }
        return null;
      },
      set: function (newValue) {
        if (newValue == null) {
          this.filters.date = null;
        }
      },
    },
  },

  filters: {
    gnsDate: function (value) {
      return gnsDate(value);
    },

    format_money: function (value) {
      return formatMoney(value);
    },
  },

  created() {
    let date = new Date();
    let month = date.getMonth();
    let year = date.getFullYear();
    this.maxFilterDate = year + "-" + (month + 1);
    this.minFilterDate = year - 1 + "-01";
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Recibos Digitales");
    this.$store.commit("stopProcessing");
    this.$store.commit("setActiveNav", 2);
    this.getList();
  },

  methods: {
    getColor(item) {
      if(item.aprobado) {
        return 'success'
      } else {
        return 'red'
      }
    },

    slugify(value) {
      return slugify(value);
    },

    openEstadoModal(liquidacion) {
      if (liquidacion.aprobado) {
        this.modalEstadoLiquidacion = liquidacion;
        this.updateStatusLiquidacion();
      } else {
        this.modalEstadoLiquidacion = liquidacion;
        this.modalEstadoTitle =
          "Liquidacion: " +
          liquidacion.DescTipoLiq +
          " - " +
          gnsDate(liquidacion.MesDeLiq);
        this.modalEstadoSubtitle =
          "Registrar la No Conformidad de lo liquidado";
        this.modalEstado = true;
      }
    },

    autoCloseModalEstado() {
      this.modalEstadoLiquidacion.aprobado = !this.modalEstadoLiquidacion
        .aprobado;
      this.modalEstado = false;
      this.modalEstadoMotivo = "";
    },

    formatDate(datetime) {
      if (!datetime) return null;

      const [date, time] = datetime.split(" ");
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year} ${time}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async getList() {
      this.loading = true;
      this.records.loading = true;

      const data = await getLiquidaciones();

      if (data && data.liquidaciones.length > 0) {
        var liquidaciones = data.liquidaciones;
        this.formatLiquidaciones(liquidaciones);
        this.records.items = this.records.items.concat(liquidaciones);
      }

      if (data && data.tipos.length > 0) {
        this.filters.tiposFilter = data.tipos;
      }

      this.loading = false;
      this.records.loading = false;
    },
    formatLiquidaciones(collection) {
      collection.map((l) => {
        l.month = gnsDate(l.MesDeLiq);
        l.aprobado = l.idEstEncUsu == "3";
      });
    },
    async filtrarLiquidaciones() {
      this.loading = true;
      this.records.loading = true;
      let filters = {
        month: this.filters.date ? this.filters.date + "-01" : null,
        idTipoLiq: this.filters.idTipoLiq,
      };
      const data = await getLiquidacionesSearch(filters);
      this.formatLiquidaciones(data);
      this.records.items = data;

      this.loading = false;
      this.records.loading = false;
    },

    goTo(record) {
      this.$router.push({
        name: "detalle_liquidacion",
        params: { id: record.id_Encabezado },
      });
    },

    navigate() {
      this.sheet = false;
      this.goTo(this.records.current);
    },

    async download(item) {
      this.sheet = false;

      if (item.Forma == "ZF" || item.Forma == "SQL" || item.Forma == 'RBD') {
        this.$router.push({
          name: "detalle_calculo",
          params: { id: item.idDetaCalc },
        });
      }

      if (item.Forma == "DAT") {
        this.dialog = true;
        this.dialogContent = item.DatoRuta;
      }

      if (item.Forma == "URL") {
        window.open(item.DatoRuta, "_blank");
      }

      if (
        item.Forma != "ZF" &&
        item.Forma != "DAT" &&
        item.Forma != "URL" && 
        item.Forma != "RBD" && 
        item.Forma != "SQL"
      ) {
        this.$store.commit("setSnackBar", {
          message: "Tipo de documento no soportado para visualizar",
          show: true,
          color: "#cc0605",
          timeout: 10000,
        });
      }
    },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },

    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },

    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },

    bottomSheet(item) {
      //this.records.current = item
      //this.navigate()
      this.records.current = item;
      this.sheet = true;
    },

    exportData() {
      alert("Proximamente");
    },

    async updateStatusLiquidacion() {
      this.modalEstadoSending = true;

      let params = {
        id_encabezado: this.modalEstadoLiquidacion.id_Encabezado,
        estado: this.modalEstadoLiquidacion.aprobado ? "3" : "4",
        mensaje: this.modalEstadoLiquidacion.aprobado
          ? ""
          : this.modalEstadoMotivo,
      };

      try {
        await updateEstadoLiquidacion(params);

        if (!this.modalEstadoLiquidacion.aprobado) {
          this.$store.commit("setSnackBar", {
            message: "Has enviado el descargo correctamente",
            show: true,
            color: "#008f39",
            timeout: 10000,
          });
        }
      } catch (e) {
        this.modalEstadoLiquidacion.aprobado = !this.modalEstadoLiquidacion
          .aprobado;
        console.log(e);
      }

      this.modalEstado = false;
      this.modalEstadoSending = false;
      this.modalEstadoMotivo = "";
      this.modalEstadoLiquidacion = null;
    },
  },
};
</script>

<style>
.v-banner__wrapper,
.v-banner__content {
  display: block !important;
}

.v-banner__wrapper {
  border-bottom: 0 !important;
  padding: 1rem !important;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.modalDescargar .headline {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 19px;
  text-align: center;
  color: #131314;
  display: flex;
  flex-flow: row;
  align-items: baseline;
  padding-right: 1rem;
}

.recibo {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
  border-radius: 20px 20px 0px 20px;
}

.recibo:before {
  border-radius: 20px 20px 0px 20px;
}

.liquidaciones {
  text-decoration: none !important;
}

a {
  text-decoration: none;
}

.v-card {
  transition: opacity 300ms ease-in-out;
  transition: transform 300ms;
  box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5) !important;
  border-radius: 20px 20px 0px 20px !important;
}
.v-card:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.v-card:hover {
  .liquidaciones-disabled {
    .v-btn {
      color: #c47920 !important;
    }
  }
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.v-toolbar {
  // box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
  // border-radius: 20px 20px 0px 20px;
  box-shadow: none;
  border-radius: 0;
  background-color: #fafafa !important;
}

.fechas {
  height: min-content !important;
  background: transparent !important;
  letter-spacing: 0.0892857143em;
}

.fechas:hover {
  background: transparent !important;
  opacity: 1 !important;
}
.fechas::before {
  background: transparent !important;
  opacity: 1 !important;
}

.mes {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #434343;
}
.liquido {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(67, 67, 67, 0.5);
}
.date {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: rgba(67, 67, 67, 0.5);
}

.tipo-liquidacion {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.mensualidad--text {
  color: #ff7f04;
}
.jornales--text {
  color: #ff7f04;
}
.aguinaldos--text {
  color: #79b100;
}
.licencia--text {
  color: #9c80f7;
}
.liquidacion-final--text {
  color: #ff0404;
}
.sinliq--text {
  font-size: 12px;
  color: #808080;
}
.reliquidaciones--text {
  color: #c50000;
}
.capacitaciones--text {
  color: #00d890;
}

.filtro-banner {
  background: white;
}

.switch-flex .v-list-item__action-text{
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.switch-liquidacion {
  margin-right: -6px !important;
}

.switch-liquidacion .v-input--selection-controls__input{
  width: 40px !important;
}

.switch-liquidacion .v-input__slot{
  display: flex !important;
  justify-content: flex-end;
}
</style>